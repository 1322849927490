.select-role-card-wrap {
  width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select-role-card {
    background-color: #fff;
    border: 1px solid rgba(0, 176, 255, 0.5);
    border-radius: 42px;
    box-shadow: 4px 16px 24px 0 rgb(10 94 255 / 12%);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0 15px 0 0;
    padding: 10px 0;
    position: relative;
    width: 45%;
  }
}
.signin-wrap {
  .signin-box {
    align-items: center;
    background-color: #fff;
    border: 1px solid rgba(0, 176, 255, 0.5);
    border-radius: 42px;
    box-shadow: 4px 16px 24px 0 rgb(10 94 255 / 12%);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 30px 20px;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    font-family: "Open Sans";
    text-transform: uppercase;
    z-index: 1;
    div {
      max-width: 400px;
    }
    &:hover {
      background-color: rgba(0, 176, 255, 0.1);
    }
  }
}

@media screen and (max-width: 1024px) and (max-height: 600px) {
  .signin-wrap {
    flex-direction: row;
    .signin-box {
      div {
        max-width: 300px;
      }
    }
  }
}

@media screen and (max-width: 980px) and (max-height: 500px) {
  .signin-wrap {
    .signin-box {
      div {
        max-width: 250px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .signin-wrap {
    flex-direction: column;
    .signin-box {
      div {
        max-width: 250px;
      }
    }
  }
}
@media screen and (max-width: 768px) and (min-height: 0px) and (max-height: 650px) {
  .signin-wrap {
    flex-direction: row;
    .signin-box {
      div {
        max-width: 250px;
      }
    }
  }
}
@media screen and (max-width: 450px) and (min-height: 0px) and (max-height: 650px) {
  .signin-wrap {
    flex-direction: column;
    .signin-box {
      div {
        max-width: 250px;
      }
    }
  }
}
