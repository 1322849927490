.design::before {
  content: "";
  position: absolute;
  top: 60px;
  left: 0;
  background-image: url("../../images/green-dots2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 105px;
  height: 190px;
  z-index: 0;
}
.design::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("../../images/circle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 320px;
  height: 320px;
  z-index: 0;
}
.design2 {
  background-color: #f2faff;
}
.design2::before {
  content: "";
  position: absolute;
  bottom: 60px;
  left: 0;
  background-image: url("../../images/triangle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 293px;
  height: 235px;
  z-index: 0;
}
.design2::after {
  content: "";
  position: absolute;
  bottom: 120px;
  right: 0;
  background-image: url("../../images/blue-dots.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 105px;
  height: 190px;
  z-index: 0;
}

@media screen and (max-width: 1170px) {
  .design::before {
    top: 60px;
    left: 0;
    width: 90px;
    height: 180px;
  }
  .design::after {
    top: 0;
    right: 0;
    width: 250px;
    height: 250px;
  }
  .design2::before {
    bottom: 60px;
    left: 0;
    width: 203px;
    height: 165px;
  }
  .design2::after {
    bottom: 120px;
    right: 0;
    width: 90px;
    height: 180px;
  }
}
@media screen and (max-width: 980px) {
  .design::after {
    top: 0;
    right: 0;
    width: 211px;
    height: 210px;
  }
}
@media screen and (max-width: 768px) {
  .design::before {
    top: 60px;
    left: 0;
    width: 100px;
    height: 150px;
  }
  .design::after {
    top: 0;
    right: 0;
    width: 180px;
    height: 180px;
  }
  .design2::before {
    bottom: 60px;
    left: 0;
    width: 153px;
    height: 105px;
  }
  .design2::after {
    bottom: 120px;
    right: 0;
    width: 100px;
    height: 150px;
  }
}
