.App {
  text-align: center;
}
body {
  overflow: hidden;
  margin: 0;
  position: relative;
  z-index: 0;
  min-height: 100vh;
}
.background-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
}
.background-wrapper::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 400px;
  width: 116px;
  height: 206px;
  background: url("./images/green-dots2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.background-wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 293px;
  height: 235px;
  background: url("./images/triangle.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
/* body::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 236px;
  height: 236px;
  background: url("./images/hollow-circle.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}
body::before {
  content: "";
  position: absolute;
  right: 0px;
  top: 0;
  width: 275px;
  height: 275px;
  background: url("./images/blue-design2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
} */
.button {
  background: #00b0ff;
  color: #fff;
  border-radius: 4px;
  border: none;
  padding: 10px 30px;
  font-weight: 600;
  font-family: "Glober";
}
.course_title {
  line-height: 36px;
  font-size: 24px;
  margin: 0 0 16px;
  font-weight: 500;
  font-family: "Open Sans";
}
.course_desc {
  font-weight: 400;
  font-family: "Open Sans";
  color: rgba(0, 0, 0, 0.86);
}
.card-wrap {
  cursor: pointer;
  border-radius: 8px !important;
  padding: 10px;
  color: rgba(0, 0, 0, 0.8) !important;
  box-shadow: none !important;
}
.color-1 {
  background-color: #cce8db !important;
}
.color-2 {
  background-color: #fadae2 !important;
}
.color-3 {
  background-color: rgba(190, 180, 214, 0.4) !important;
}
.color-4 {
  background-color: #90cbf9 !important;
}
.color-5 {
  background-color: #f8b3ca !important;
}
.color-6 {
  background-color: #c5d9fc !important;
}
.color-7 {
  background-color: #fff3cf !important;
}
.color-8 {
  background-color: #cadebd !important;
}
.color-9 {
  background-color: #daf0ee !important;
}
.color-10 {
  background-color: #fddccb !important;
}
@media (max-width: 850px) {
  body::after {
    display: none;
  }
  body::before {
    display: none;
  }
  main::after {
    right: 0px;
    top: 400px;
    width: 116px;
    height: 206px;
  }
  main::before {
    left: 0;
    top: 0;
    width: 203px;
    height: 163px;
  }
}
@font-face {
  font-family: "Glober";
  src: url("./fonts/GloberW01-Bold.eot");
  src: url("./fonts/GloberW01-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/GloberW01-Bold.woff") format("woff"),
    url("./fonts/GloberW01-Bold.ttf") format("truetype"),
    url("./fonts/GloberW01-Bold.svg#GloberW01-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Glober";
  src: url("./fonts/GloberW01-SemiBold.eot");
  src: url("./fonts/GloberW01-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/GloberW01-SemiBold.woff") format("woff"),
    url("./fonts/GloberW01-SemiBold.ttf") format("truetype"),
    url("./fonts/GloberW01-SemiBold.svg#GloberW01-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Glober";
  src: url("./fonts/GloberW01-Book.eot");
  src: url("./fonts/GloberW01-Book.eot?#iefix") format("embedded-opentype"),
    url("./fonts/GloberW01-Book.woff") format("woff"),
    url("./fonts/GloberW01-Book.ttf") format("truetype"),
    url("./fonts/GloberW01-Book.svg#GloberW01-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Glober";
  src: url("./fonts/GloberW01-Light.eot");
  src: url("./fonts/GloberW01-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/GloberW01-Light.woff") format("woff"),
    url("./fonts/GloberW01-Light.ttf") format("truetype"),
    url("./fonts/GloberW01-Light.svg#GloberW01-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "GloberW01";
  src: url("./fonts/GloberW01-Regular.eot");
  src: url("./fonts/GloberW01-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/GloberW01-Regular.woff") format("woff"),
    url("./fonts/GloberW01-Regular.ttf") format("truetype"),
    url("./fonts/GloberW01-Regular.svg#GloberW01-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
